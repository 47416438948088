import { useState, useRef, useEffect } from "react";
import { Button, Dropdown, Space } from 'antd';
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 
import axios from "axios";
import { yHost }  from "./settings";
import { useNavigate, useLocation } from "react-router-dom";
import useitemStore from "./store"


export default function Submitexpl() {

    const location = useLocation();
    const [loading, setLoading] = useState(false)



    const [textbookreference,settextbookreference]= useState("")
    const [explanation,setexplanation]= useState("")
   
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();




    const formElemRef = useRef()

    const navigate = useNavigate();



    const expSubmitted = useitemStore((state) => state.expSubmitted)
    const setexpSubmitted = useitemStore((state) => state.setexpSubmitted)

    const itemsArr = useitemStore((state) => state.items)
    const setitems = useitemStore((state) => state.setitems)

        useEffect(()=>{
            console.log(location.state)
        },[])
    
      

        

          

            function handleChange1(e) {
                // console.log(e.target.files[0])
                // console.log(e.target.files[0].uri)
                // console.log(e.target.files[0].url)
            
                if(e.target.files[0].size > 2000000 ){alert("file should be less then 2 MB"); return}
                setFile1(URL.createObjectURL(e.target.files[0]));
            }

            
            function handleChange2(e) {
              // console.log(e.target.files[0])
              // console.log(e.target.files[0].uri)
              // console.log(e.target.files[0].url)
          
              if(e.target.files[0].size > 2000000 ){alert("file should be less then 2 MB"); return}
              setFile2(URL.createObjectURL(e.target.files[0]));
          }

            
 const handleSubmit=(e)=>{
    e.preventDefault()
    const formData = new FormData(formElemRef.current);
    console.log(formData)

    formData.append("mcqnum",location.state.mcqnum )
   
    formData.append("useranswer", location.state.ans )

   if (!location.state.ownmcq){
    axios({
        method:'post',
        url:`${yHost}/submitExplanation`, 
        data: formData, 
       withCredentials:true
   })
      .then((response) => {
          const data = response.data;
          var oldArr = expSubmitted
          var newArr = [...expSubmitted,location.state.mcqnum]
         if(data.msg == "success"){ alert("explanation submitted successfully"); setexpSubmitted(newArr)  ; navigate(-1)}
        })
        .catch((error) => console.log(error));
   }else{
    axios({
        method:'post',
        url:`${yHost}/submitExplanationOwnMcq`, 
        data: formData, 
       withCredentials:true
   })
      .then((response) => {
          const data = response.data;
          var oldArr = itemsArr

          var newArr =  oldArr.map((i)=>{ if(location.state.mcqnum == i.mcqnum){i.contriPostedExpl = true} ; return i })

         
         if(data.msg == "success"){ alert("explanation submitted successfully"); setitems(newArr)  ; navigate(-1)}
        })
        .catch((error) => console.log(error));
   }
   
 }


  return (
    <>

          {loading?
          <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
          <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
          </div>
                 :

  <div style={{backgroundColor:"black"}}>

   
        <form style={{marginTop:"40px", marginRight:"auto", marginLeft:"auto", display:"block",  width:"95%", maxWidth:"800px" }} ref={formElemRef} onSubmit={handleSubmit}>
        <h1 style={{color:"orange"}}>Post your Explanation / Textbook reference</h1>
            <p style={{color:"white"}}>Ques: {location.state.question}</p>
           
            {location.state.contriQuespic ?
            <img style={{ maxWidth:"100px" }} src=""  />:null}

            <p style={{color:"white"}}>A: {location.state.option1}</p>
            <p style={{color:"white"}}>B: {location.state.option2}</p>
            <p style={{color:"white"}}>C: {location.state.option3}</p>
            <p style={{color:"white"}}>D: {location.state.option4}</p>
            <p style={{color:"white"}}>Your answer: {location.state.ans}</p>
        


                                




            <label htmlFor="textbookreference"><span style={{color:"white", marginRight:"10px", width:"200px"}}>Textbook reference: </span></label>
            <input type="text" id="textbookreference" value={textbookreference}   name="textbookreference" onChange={e => {  settextbookreference(e.target.value)  }}  />
            <br/> <br/>

            <label htmlFor="explanation"><span style={{color:"white", marginRight:"10px", width:"200px"}}>Explanation: </span></label>
            <textarea type="text" id="explanation" value={explanation}  name="explanation"  onChange={e => {  setexplanation(e.target.value)  }} rows="4" cols="50" ></textarea>
            <br/>
            <br/>

            <label htmlFor="pic1">
            <span style={{color:"white", marginRight:"10px"}}>you can upload image attached with explanation</span> </label>
            <input  name="pic1" type="file" onChange={handleChange1} accept=".jpg,.png" />
            <br/>
            <br/>
            {file1 ?  <img style={{ maxWidth:"100px" }} src={file1}  />:null}
            <br/>
            

            <label htmlFor="pic2">
            <span style={{color:"white", marginRight:"10px"}}>you can upload one more image, if needed</span> </label>
            <input  name="pic2" type="file" onChange={handleChange2} accept=".jpg,.png" />
            <br/>
        
            {file2 ?  <img style={{ maxWidth:"100px" }} src={file2}  />:null}
            <br/>
            <br/>
            <button className="submitBtn" type="submit" >Submit</button>
        </form>
  </div>

}
   </>
  );
}

