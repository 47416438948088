import { useEffect, useState , useRef, createRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Dropdown, Space } from 'antd';
import Modal from 'react-modal';
import moment from "moment";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:'95%',
    height:"95%"
  },
};




export default function Mcqs() {
    const [ loading, setloading ] = useState(true)
  
    const [items, setitems] = useState([])
    const [wholeArr, setwholeArr] = useState([])
    const [fixedwholeArr, setfixedwholeArr] = useState([])
    const [keyVal, setkeyVal] = useState(0)
    const [yObj, setyObj] = useState({})
    const [hasMore, sethasMore] = useState(true)
   
    const [ fetchMoreChala, setfetchMoreChala] = useState(0)

    
    const [ yourAns, setYourAns ] = useState([{mcqnum:1,ans:"A"}])
    const [modalIsOpen, setIsOpen] = useState(false);
//console.log(yourAns)
const [ ansObj, setansObj] = useState({})




    const {state} = useLocation();
    
    //var yHost = "http://localhost:2000"
var yHost = ""

  

    useEffect(() => {
      var localyObj = state;
      localyObj.keyVal = keyVal;
      setyObj(localyObj);

      fetchMcqs();
    }, []);



    useEffect(() => {
      console.log(
        "items",
        items.map((i) => {
          return i.bnumber;
        })
      );
      console.log(
        "wholeArr",
        wholeArr.map((i) => {
          return i.bnumber;
        })
      );
    }, [items, wholeArr]);
   


    useEffect(() => {
      console.log("fetchmorechala count", fetchMoreChala);
    }, [fetchMoreChala]);



   const fetchMcqs = async ()=>{
        axios({
            method: 'post',
            url: `${yHost}/fetchMcqs`,
            data:yObj
          })
        .then((response) => {
            setloading(false) 
          const data = response.data;
          console.log()
          if (!data.mcqArr.length) { alert('no records') }
          else {    
            setfixedwholeArr(data.mcqArr) //fetching se total books aayi, use fixedwholeArr mein store kiya
            console.log("mcqs received", data.mcqArr.length  )
            if (data.mcqArr.length <= 10) {
                console.log('10 yaa kam')
      
                   setitems(data.mcqArr )
              }
              else if (data.mcqArr.length > 10) {  
                                
      
      
                const shuruArr = data.mcqArr.splice(0, 10);  //shuru ke 10 item liye
 
             console.log("shuruArrlength ab items", shuruArr.length)
             console.log("data.length splice ke bad, ab wholeArr", data.mcqArr.length)
            
   
           
              //peeche se data aaya use fixedwholeArr mein store kiya, data meins e aage ki 10 book kat kar items ko di, baki wholeArr mein store ki
              setitems(shuruArr)
              setwholeArr(data.mcqArr)
            }
        }
        })
        .catch((error) => console.log(error));
    }



    const fetchMoreData = () => {
        console.log("fetchmore chala", fetchMoreChala)
     
     var fetchMoreChalaLocal = fetchMoreChala+1
     console.log("fetchMoreChalaLocal",fetchMoreChalaLocal)
     setfetchMoreChala(fetchMoreChalaLocal)
     if( fetchMoreChalaLocal % 8 == 0 ){  
     
       var yObjLocal = yObj
       yObjLocal.keyVal = yObjLocal.keyVal+1
     
     axios({
         method: 'post',
         url: `${yHost}/fetchMcqs`,
         data: yObjLocal
       })
         .then((response) => {
           const data = response.data; 
     
           console.log("API more se books milie", data.length)
           console.log("wholeArr length", wholeArr.length)
           setwholeArr(wholeArr.concat(data))
           console.log("more se books mili vo wholeArr mein concat ki wholeArr length hui", wholeArr.length)
           setyObj(yObjLocal)
           
         })
         .catch((error) => console.log(error));
     
     }
     
         if (!wholeArr.length) {
             sethasMore(false)
             //return se fetchMoreData function yahi stop ho jayega
             return;
           }
     
           if (wholeArr.length >= 10) { 
             
                // console.log("wholeArr", wholeArr.length)
                 var yahaKawholeArr  = wholeArr
                 var splicedPart = yahaKawholeArr.splice(0, 10)
                 setitems((prev)=>prev.concat(splicedPart))
                 //console.log("wholeArr after 10 minus", yahaKawholeArr.length)
                 setwholeArr(yahaKawholeArr)
             
           }
       
           else if (wholeArr.length < 10) {
             console.log('wholeArr 10 se kam')
            
                 setitems((prev)=>prev.concat(wholeArr))
                 setwholeArr([])
                
          
           }
       
     
     
       };


       
const submitAns = (mcqnum) => {
  axios({
    method: 'post',
    url: `${yHost}/submitAns`,
    data: yourAns.filter((i)=>{ if( i.mcqnum == mcqnum){return i}  })[0]
  })
    .then((response) => {
      const data = response.data; 

      var arr1 = yourAns.filter((i)=>{ if(i.mcqnum !=mcqnum ){ return i}})
      var addObj = {mcqnum:mcqnum, ans:yourAns.filter((i)=>{ if( i.mcqnum == mcqnum){return i}  })[0].ans , rightAns:data.mcqobj.ans }
      setYourAns([...arr1,addObj])
      setansObj(data.mcqobj)
     
    
    })
    .catch((error) => console.log(error));
}

function afterOpenModal() {

}


  return (
    <div>
       <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={()=> setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
       <h1>model</h1>
       
       <button style={{position:"absolute", left:'5px',top:'5px'}} onClick={()=> setIsOpen(false)}>close</button>
       <p>{ansObj.question}</p>
       <p style={{color:"orange", fontSize:"12px", marginBottom:"10px"}}>posted by {ansObj.postedBy} at {moment(ansObj.createdOn).fromNow()}</p> 
      
       {ansObj.contriQuespic ?
       <img src={yHost+"/uploads/" + ansObj.contriQuespic       } alt="Girl in a jacket" width="50" height="50"/>
       :null}

       <p>A: {ansObj.option1}</p>
       <p>B: {ansObj.option2}</p>
       <p>C: {ansObj.option3}</p>
       <p>D: {ansObj.option4}</p>
       <p>Right Answer : {ansObj.ans}</p>

       {ansObj.contriPostedExpl ?
       <>
       <h3 style={{ textDecoration: 'underline'}}>{ansObj.postedBy}'s explanation</h3> <br/>
       <div>{ ReactHtmlParser(ansObj.contriexpl.replace("http://mcqbuster.com/assets/uploads/user",yHost+'/uploads')) }</div>
       </>:null}
        
        {ansObj.contritextbref ? 
        <p>Textbook reference posted by {ansObj.postedBy}: {ansObj.contritextbref}</p>:null}

       {ansObj.adminPostedExpl ?
        <>
        <h3 style={{ textDecoration: 'underline'}}>Admin's explanation</h3> <br/>
        <div>{ ReactHtmlParser(ansObj.adminexpl) }</div>
        </>:
       <p>Admin didn't posted his explanation yet.</p>}

     
      </Modal>

          {loading?
            <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
            <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
            </div>
                 :
        
    <div >
     <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}

            loader={<h3 style={{ color: "orange", textAlign: "center" }}>Loading...</h3>}
            //key={this.state.keycount}
            endMessage={
              <h3 style={{ textAlign: "center", color: "orange",float: "right" }}>
                You have seen it all, More Textbooks are coming soon.
              </h3>
            }
          >
            {items.map((i,index)=>{ return (
                <div style={{width:"100%",maxWidth:"600px", borderColor: "aqua", borderWidth:"1px", borderStyle:"solid", borderRadius:"5px", marginRight:"auto", marginLeft:"auto", display:"block",paddingLeft:"20px",paddingRight:"10px",marginTop:"20px" }} key={index}>
                
                 
                 <p style={{color:"orange", }}>{i.question}</p>

                 {i.contriQuespic ?
                 <img src={yHost+"/uploads/" + i.contriQuespic       } alt="Girl in a jacket"    style={{width:"100%", maxWidth:"400px",height:"auto"}}/>
                 :
                 null } 
                 



                 <p style={{color:"orange", fontSize:"12px", marginBottom:"10px"}}>posted by {i.postedBy} at {moment(i.createdOn).fromNow()}</p> 
                 <p style={{color:"white", fontSize:"12px", marginBottom:"10px"}}>{i.mcqnum}</p> 
                 <div style={{display:"flex", flexDirection:"row"}}> 
                 <input type="checkbox" 
                 value= { "A" }
                 checked={false ?! yourAns.length :   yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length ? yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="A" : false }
                onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })

                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }


                        
                      }}  
                />
                 <p><span style={{color:"orange"}}>A: </span><span style={{color:"white"}}>{i.option1}</span></p>
                 </div>



                 <div style={{display:"flex", flexDirection:"row"}}> 
                 <input type="checkbox"  
                 value= { "B" }
                 checked={false ?! yourAns.length :   yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length ? yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="B" : false }
                onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                 <p><span style={{color:"orange"}}>A: </span><span style={{color:"white"}}>{i.option2}</span></p>
                 </div>



               <div style={{display:"flex", flexDirection:"row"}}> 
                 <input type="checkbox"    
                 value= { "C" }
                checked={false ?! yourAns.length :   yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length ? yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="C" : false }
                
                onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                 <p><span style={{color:"orange"}}>A: </span><span style={{color:"white"}}>{i.option3}</span></p>
                 </div>



               <div style={{display:"flex", flexDirection:"row"}}> 
                 <input type="checkbox"   
                 value= { "D" }
                 checked={false ?! yourAns.length :   yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  }).length ? yourAns.filter((itemm)=>{  if(itemm.mcqnum == i.mcqnum){return itemm}  })[0].ans=="D" : false }
                onChange={e => {  
                        if(yourAns.length){
                        var arr1 =  yourAns.filter((itemp,indexx)=>{  if(itemp.mcqnum != i.mcqnum){ return itemp }  })
                          setYourAns([...arr1,{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }else{
                          setYourAns([{mcqnum:i.mcqnum, ans:e.target.value}]) 
                        }
                        
                      }}  
                />
                 <p><span style={{color:"orange"}}>A: </span><span style={{color:"white"}}>{i.option4}</span></p>
                 </div>
                 <p style={{color:"white"}}>To see right answer, first Submit your answer.</p>

                

                  <p style={{color:"wheat"}}>{ yourAns.filter((itt)=>{  if(itt.mcqnum == i.mcqnum ){return itt}   }).length && yourAns.filter((itt)=>{  if(itt.mcqnum == i.mcqnum ){return itt}   })[0].rightAns ?   
                  yourAns.filter((itt)=>{  if(itt.mcqnum == i.mcqnum ){return itt}   })[0].ans ==yourAns.filter((itt)=>{  if(itt.mcqnum == i.mcqnum ){return itt}   })[0].rightAns ? 
                  <div>
                  <p>Right</p>
                  <div style={{display:"flex", flexDirection:"row"}}>
                  <p style={{marginRight:"10px"}}>Explore explanations and text book references for this answer</p>
                  
                  <Button style={{alignSelf:"center"}} type="primary" onClick={()=>setIsOpen(true)}>
                   explore
                  </Button>
                  </div>

                  <Button type="primary">
                   Submit your own explanation / textbook references
                  </Button>
                  </div>
                  :
                  <div>
                  <p>Wrong</p>
                   <div style={{display:"flex", flexDirection:"row"}}>
                  <p style={{marginRight:"10px"}} >Explore explanations and text book references for this answer</p>
                  <Button style={{alignSelf:"center"}} type="primary" onClick={()=> setIsOpen(true)}>
                   explore
                  </Button>
                  </div>
                  </div>
                  :
                  <Button type="primary" onClick={()=>{submitAns(i.mcqnum)}}>
                  Submit
                  </Button>
                   }</p>

                </div> 
                
            )})}
          
          </InfiniteScroll>
    </div>
    }
    </div>
  );
}

