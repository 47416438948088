
import { useEffect, useState , useRef, createRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 

import { Button, Dropdown, Space } from 'antd';
import Modal from 'react-modal';

import moment from "moment";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import { yHost }  from "./settings";

export default function AdminAns() {
    const [ ansObj, setansObj] = useState({})
    const location = useLocation();
    const [loading, setloading ] = useState(true)
    const [showrefer, setshowrefer ] = useState(false)
    const [referExpl, setreferExpl ] = useState("")
    const [referAns, setreferAns ] = useState("")
    const [referUserFname, setreferUserFname ] = useState("")

    useEffect(()=>{
   
       
       if(location.state.ansObj){ console.log( "yaha aaya");  setansObj(location.state.ansObj); setloading(false)}
       else{ console.log(location.state.ansObj, " null hai ", location.state.mcqnum) ; fetchAnsObj(location.state.mcqnum)}
       
     
    },[])

    
    const fetchAnsObj = (mcqnum) => {
      alert("fetch")
        console.log("fetchAnsObj",mcqnum)
        axios({
          method: 'post',
          url: `${yHost}/fetchAnsObj`,
          data: {mcqnum},
          withCredentials:true
      
        })
          .then((response) => {
            const data = response.data; 
            console.log("laya",data.mcqobj)
            setloading(false)
            setansObj(data.mcqobj)
           
           
          
          })
          .catch((error) => console.log(error));
      }

    const referOtheruserExpl = (mcqnum,email) =>{
      setshowrefer(true)
      var arr = ansObj.userexplanations.filter((i)=>{if(i.email == email){ return i }})
        if(arr.length){ setreferExpl(arr[0].expl);setreferAns(arr[0].useranswer); setreferUserFname(arr[0].fname)   } 
    }


  return (
  <div style={{backgroundColor:"black"}}>
    
    {loading?
          <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
          <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
          </div>
                 :
    <div style={{backgroundColor:"white" ,paddingLeft:"20px", maxWidth:"700px", width:"95%", marginLeft:"auto", marginRight:"auto"}}>
        
        <p>{ansObj.question}</p>
       <p style={{color:"orange", fontSize:"12px", marginBottom:"10px"}}>posted by {ansObj.postedBy} at {moment(ansObj.createdOn).fromNow()}</p> 
      
       {ansObj.contriQuespic ?
       <img src={yHost+"/uploads/" + ansObj.contriQuespic       } alt="Girl in a jacket" width="50" height="50"/>
       :null}

       <p>A: {ansObj.option1}</p>
       <p>B: {ansObj.option2}</p>
       <p>C: {ansObj.option3}</p>
       <p>D: {ansObj.option4}</p>
       <p>Right Answer by {ansObj.postedBy}: {ansObj.ans}</p>
       <p>Right Answer by admin {ansObj.adminAns}</p>
       

       {ansObj.adminPostedExpl ?
        <>
        <h3 style={{ textDecoration: 'underline'}}>Admin's explanation</h3> <br/>
        {ansObj.adminexpl.includes("Refer here to best explanation") ?
        <>
          <p>Admin recommends: This user provided best explanation, click to see</p>
          {!showrefer?<Button style={{}} type="primary" onClick={()=>referOtheruserExpl(ansObj.mcqnum, ansObj.adminexpl.trim().split(" ")[ansObj.adminexpl.trim().split(" ").length-1])}>
           click
         </Button>:null }
          
          {showrefer ?
          <>
          <p style={{color:"orange"}}>{referUserFname} posted this explanation</p>
          <div>{ ReactHtmlParser(referExpl) }</div>
          <hr/>
          </>
          :null
          }
          

         </> 
         : <div>{ ReactHtmlParser(ansObj.adminexpl) }</div>  }
        
        </>:
       <p>Admin didn't posted his explanation yet.</p>}
        
        {ansObj.admintextbref ? 
        <p>Textbook reference posted by admin: {ansObj.admintextbref}</p>:<p>Admin didn't posted his textbook reference yet.</p>}
    </div>
    }
    </div>
  );
}

