import Header from "./Header";
import Footer from "./Footer";


export default function Teachers() {


  return (
  
   
<div>
  

 
 <Header/>
  
    <section className="trust">
        <div className="container">
            <div style={{marginTop:"100px"}} className="row">
                <div className="offset-xl-1 col-xl-6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="800">
                    <div className="title">
                        <h6 className="title-primary">For Teachers / Medical Examiners</h6>
                        <h1>MCQ Buster is useful to conduct online examination</h1>
                    </div>
                    <p>Medical Teachers can conduct class assessments / online examinations on MCQ Buster App, its super easy and handy way, Test is time bound, and students will not have time to see answer from Internet. There is no way to cheat this app, results will be always accurate.
                    </p>
                    <p>In classrooms, where students can not discuss each other, online exam can be conducted, quick and easy to use for examiners. Students can not fool this app.</p>
                    <p>Presently 20 is the limit for free version of App, it means 20 students can give test, if you need to test more student, you can get version of higher limits, contact us</p>
                    <p>Just try it once, We are sure you will like it, to conduct a class assessment, you have to ask students to install the app, and in your phone you should have saved mobile numbers of all students. Now app is ready, you can send them a test, and ask them to start a test, as your ring a buzzer. You can also make a group, including all class students. <a href="https://www.youtube.com/watch?v=rWDr6c8_FqA">See this Video </a></p>
                </div>
                <div className="col-xl-5 gallery">
                    <div className="row no-gutters h-100" id="lightgallery">
                        <a href="https://lorempixel.com/600/400/" className="w-50 h-100 gal-img" data-aos="fade-up"
                            data-aos-delay="200" data-aos-duration="400">
                            <img className="img-fluid" src="assets/images/galleryexam1.jpg" alt="Gallery Image"/>
                            <i className="fa fa-caret-right"></i>
                        </a>
                        <a href="https://lorempixel.com/600/400/" className="w-50 h-50 gal-img" data-aos="fade-up"
                            data-aos-delay="400" data-aos-duration="600">
                            <img className="img-fluid" src="assets/images/gallery2.jpg" alt="Gallery Image"/>
                            <i className="fa fa-caret-right"></i>
                        </a>
                        <a href="https://lorempixel.com/600/400/" className="w-50 h-50 gal-img gal-img3" data-aos="fade-up"
                            data-aos-delay="0" data-aos-duration="600">
                            <img className="img-fluid" src="assets/images/galleryexam3.jpg" alt="Gallery Image"/>
                            <i className="fa fa-caret-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
   
 

<Footer/>
   
  
</div>

  );
}

