import { useState, useRef, useEffect } from "react";
import { Button, Dropdown, Space } from 'antd';
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { yHost }  from "./settings";

export default function Postamcq() {


    const [loading, setLoading] = useState(true)
    const [question, setquestion] = useState("")
    const [option1, setoption1] = useState("")
    const [option2, setoption2] = useState("")
    const [option3, setoption3] = useState("")
    const [option4, setoption4] = useState("")
    const [option5, setoption5] = useState("")
    const [option6, setoption6] = useState("")
    const [option7, setoption7] = useState("")
    const [option8, setoption8] = useState("")
    const [option9, setoption9] = useState("")
    const [option10, setoption10] = useState("")
    const [option11, setoption11] = useState("")
    const [option12, setoption12] = useState("")
    const [option13, setoption13] = useState("")
    const [option14, setoption14] = useState("")
    const [option15, setoption15] = useState("")
    const [option16, setoption16] = useState("")
    const [option17, setoption17] = useState("")
    const [option18, setoption18] = useState("")
    const [option19, setoption19] = useState("")
    const [option20, setoption20] = useState("")

    const [ans, setans] = useState("")
    const [textbookreference,settextbookreference]= useState("")
    const [explanation,setexplanation]= useState("")
    const [file, setFile] = useState();
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [showOptions, setshowOptions] = useState(4)

    const [allSubj, setallSubj] = useState([])
    const [allExams, setallExams] = useState([])

    const formElemRef = useRef()

    const navigate = useNavigate();



    var Arr1 = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T"]
    var optionArr = Arr1.filter((item,index)=>{  if(index+1 <= showOptions){return item}  })

    

        useEffect(()=>{
            fetchSubjectsExams()
        },[])
    
        const fetchSubjectsExams = async()=>{
            axios({
                method:'post',
                url:`${yHost}/fetchSubjectsExams`, 
                withCredentials:true
              
           })
              .then((response) => {
                  const data = response.data;
                  setLoading(false)
                 if(data.msg == "please login" ){alert("please login"); navigate("/")}
                 setallSubj(data.allSubj)
                 setallExams(data.allExams)
                })
                .catch((error) => console.log(error));
        }

        

            function handleChange(e) {
                // console.log(e.target.files[0])
                // console.log(e.target.files[0].uri)
                // console.log(e.target.files[0].url)
            
                if(e.target.files[0].size > 2000000 ){alert("file should be less then 2 MB"); return}
                setFile(URL.createObjectURL(e.target.files[0]));
            }

            function handleChange1(e) {
                // console.log(e.target.files[0])
                // console.log(e.target.files[0].uri)
                // console.log(e.target.files[0].url)
            
                if(e.target.files[0].size > 2000000 ){alert("file should be less then 2 MB"); return}
                setFile1(URL.createObjectURL(e.target.files[0]));
            }

            
            function handleChange2(e) {
              // console.log(e.target.files[0])
              // console.log(e.target.files[0].uri)
              // console.log(e.target.files[0].url)
          
              if(e.target.files[0].size > 2000000 ){alert("file should be less then 2 MB"); return}
              setFile2(URL.createObjectURL(e.target.files[0]));
          }

            
 const handleSubmit=(e)=>{
    e.preventDefault()
    const formData = new FormData(formElemRef.current);
    console.log(formData)
    axios({
        method:'post',
        url:`${yHost}/postamcq`, 
        data: formData, 
       withCredentials:true
   })
      .then((response) => {
          const data = response.data;
         if(data.msg == "success"){alert("mcq posted successfully"); navigate("/")}
        })
        .catch((error) => console.log(error));
 }


  return (
    <>

          {loading?
          <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
          <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
          </div>
                 :
  <div style={{backgroundColor:"black"}}>
    <form style={{marginTop:"40px", marginRight:"auto", marginLeft:"auto", display:"block",  width:"95%", maxWidth:"800px" }} ref={formElemRef} onSubmit={handleSubmit}>
     <h1 style={{color:"orange"}}>Post your MCQ</h1>
     <label htmlFor="question"><span style={{color:"white", marginRight:"10px"}}>Question: </span></label>
    <textarea required type="text" id="question" value={question}  name="question"  onChange={e => {  setquestion(e.target.value)  }} rows="4" cols="50" ></textarea>
    <br/> <br/>
   

    <label htmlFor="pic">
        <span style={{color:"white", marginRight:"10px"}}>you can upload image attached with question</span> </label>
    <input  name="pic" type="file" onChange={handleChange} accept=".jpg,.png" />
   <br/>
    {file?  <img style={{ maxWidth:"100px" }} src={file}  />:null}
    <br/>
   

   


    <label htmlFor="subject">
     <span style={{color:"white"}}>select subject: </span> 
      <select required id="subject" name="subject" >
      <option value="">select subject</option>
        {allSubj.map((i,index)=>{ return (
              <option key={index} value={i}>{i}</option>
        )})}
      </select>
    </label>
    <br/>                        

    <label htmlFor="exams">
     <span style={{color:"white"}}>select exams, where it has been asked: </span> 
      <select style={{verticalAlign: "middle"}} id="exams" name="exams" multiple>
           
        {allExams.map((i,index)=>{ return (
              <option key={index} value={i}>{i}</option>
        )})}
      </select>
    </label>
    <br/>

    <label htmlFor="option1"><span style={{color:"white", marginRight:"10px"}}>option 1: </span></label>
    <input required type="text" id="option1" value={option1}  name={"option1"} onChange={e => { setoption1(e.target.value)   }}  />
    <br/>

    <label htmlFor="option2"><span style={{color:"white", marginRight:"10px"}}>option 2: </span></label>
    <input required type="text" id="option2" value={option2}  name={"option2"} onChange={e => {  setoption2(e.target.value)  }}  />
    <br/>

    <label htmlFor="option3"><span style={{color:"white", marginRight:"10px"}}>option 3: </span></label>
    <input required type="text" id="option3" value={option3}  name={"option3"} onChange={e => {  setoption3(e.target.value)  }}  />
    <br/>

    <label htmlFor="option4"><span style={{color:"white", marginRight:"10px"}}>option 4: </span></label>
    <input required type="text" id="option4" value={option4}  name={"option4"}  onChange={e => {  setoption4(e.target.value)  }}  />
    <br/>



    { showOptions > 4 ?
    <>
    <label htmlFor="option5"><span style={{color:"white", marginRight:"10px"}}>option 5: </span></label>
    <input type="text" id="option5" value={option5}  name={"option5"} onChange={e => {  setoption5(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 5 ?
    <>
    <label htmlFor="option6"><span style={{color:"white", marginRight:"10px"}}>option 6: </span></label>
    <input type="text" id="option6" value={option6}  name={"option6"} onChange={e => {  setoption6(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 6 ?
    <>
    <label htmlFor="option7"><span style={{color:"white", marginRight:"10px"}}>option 7: </span></label>
    <input type="text" id="option7" value={option7}  name={"option7"} onChange={e => {  setoption7(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 7 ?
    <>
    <label htmlFor="option8"><span style={{color:"white", marginRight:"10px"}}>option 8: </span></label>
    <input type="text" id="option8" value={option8}  name={"option8"} onChange={e => {  setoption8(e.target.value)  }}  />
    <br/>
    </>:null }


    { showOptions > 8 ?
    <>
    <label htmlFor="option9"><span style={{color:"white", marginRight:"10px"}}>option 9: </span></label>
    <input type="text" id="option9" value={option9}  name={"option9"} onChange={e => {  setoption9(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 9 ?
    <>
    <label htmlFor="option10"><span style={{color:"white", marginRight:"10px"}}>option 10: </span></label>
    <input type="text" id="option10" value={option10} name={"option10"}  onChange={e => {  setoption10(e.target.value)  }}  />
    <br/>
    </>:null }


    { showOptions > 10 ?
    <>
    <label htmlFor="option11"><span style={{color:"white", marginRight:"10px"}}>option 11: </span></label>
    <input type="text" id="option11" value={option11}  name={"option11"}  onChange={e => {  setoption11(e.target.value)  }}  />
    <br/>
    </>:null }


    { showOptions > 11 ?
    <>
    <label htmlFor="option12"><span style={{color:"white", marginRight:"10px"}}>option 12: </span></label>
    <input type="text" id="option12" value={option12} name={"option12"}  onChange={e => {  setoption12(e.target.value)  }}  />
    <br/>
    </>:null }


    { showOptions > 12 ?
    <>
    <label htmlFor="option13"><span style={{color:"white", marginRight:"10px"}}>option 13: </span></label>
    <input type="text" id="option13" value={option13}  name={"option13"} onChange={e => {  setoption13(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 13 ?
    <>
    <label htmlFor="option14"><span style={{color:"white", marginRight:"10px"}}>option 14: </span></label>
    <input type="text" id="option14" value={option14}  name={"option14"} onChange={e => {  setoption14(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 14 ?
    <>
    <label htmlFor="option15"><span style={{color:"white", marginRight:"10px"}}>option 15: </span></label>
    <input type="text" id="option15" value={option15} name={"option15"}  onChange={e => {  setoption15(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 15 ?
    <>
    <label htmlFor="option16"><span style={{color:"white", marginRight:"10px"}}>option 16: </span></label>
    <input type="text" id="option16" value={option16}  name={"option16"}  onChange={e => {  setoption16(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 16 ?
    <>
    <label htmlFor="option17"><span style={{color:"white", marginRight:"10px"}}>option 17: </span></label>
    <input type="text" id="option17" value={option17}  name={"option17"} onChange={e => {  setoption17(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 17 ?
    <>
    <label htmlFor="option18"><span style={{color:"white", marginRight:"10px"}}>option 18: </span></label>
    <input type="text" id="option18" value={option18}  name={"option18"} onChange={e => {  setoption18(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 18 ?
    <>
    <label htmlFor="option19"><span style={{color:"white", marginRight:"10px"}}>option 19: </span></label>
    <input type="text" id="option19" value={option19}  name={"option19"} onChange={e => {  setoption19(e.target.value)  }}  />
    <br/>
    </>:null }

    { showOptions > 19 ?
    <>
    <label htmlFor="option20"><span style={{color:"white", marginRight:"10px"}}>option 20: </span></label>
    <input type="text" id="option20" value={option20}  name={"option20"} onChange={e => {  setoption20(e.target.value)  }}  />
    <br/>
    </>:null }
    
    {showOptions <= 19 ? <Button type="primary" onClick={()=>setshowOptions(prev=>prev+1)}>Add one more option</Button>:null }
    <br/>
    <br/>

    <label>
     <span style={{color:"white", marginRight:"10px"}}>Right Answer: </span> 
      <select name="ans"  onChange={e => setans(e.target.value)}>
          <option  value="">select</option>
        {optionArr.map((i,index)=>{ return (
              <option key={index} value={i}>{i}</option>
        )})}
        
     
      </select>
    </label>
    <br/>
    <label htmlFor="textbookreference"><span style={{color:"white", marginRight:"10px"}}>Textbook reference: </span></label>
    <input type="text" id="textbookreference" value={textbookreference}   name={"textbookreference"} onChange={e => {  settextbookreference(e.target.value)  }}  />
    <br/>

    <label htmlFor="explanation"><span style={{color:"white", marginRight:"10px"}}>Explanation: </span></label>
    <textarea type="text" id="explanation" value={explanation}  name="explanation"  onChange={e => {  setexplanation(e.target.value)  }} rows="4" cols="50" ></textarea>
    <br/>
    <br/>

    <label htmlFor="pic1">
    <span style={{color:"white", marginRight:"10px"}}>you can upload image attached with explanation</span> </label>
    <input  name="pic1" type="file" onChange={handleChange1} accept=".jpg,.png" />
    <br/>
    <br/>
    {file1 ?  <img style={{ maxWidth:"100px" }} src={file1}  />:null}
    <br/>
    

    <label htmlFor="pic2">
    <span style={{color:"white", marginRight:"10px"}}>you can upload one more image, if needed</span> </label>
    <input  name="pic2" type="file" onChange={handleChange2} accept=".jpg,.png" />
    <br/>
   
    {file2 ?  <img style={{ maxWidth:"100px" }} src={file2}  />:null}
    <br/>
    <br/>
    <button className="submitBtn" type="submit" >Submit</button>
    </form>
  </div>

}
   </>
  );
}

