import { create } from 'zustand'

const useitemStore = create((set) => ({
    items: [],
    setitems: (arr) => set((state) => ({ items: arr })),

    mcqcount:0,
    setmcqcount: (val) => set((state) => ({ mcqcount: val })),

    yourAns: [],
    setYourAns: (arr) => set((state) => ({ yourAns: arr })),

    keyVal:0,
    setkeyVal: (val) => set((state) => ({ keyVal: val })),

    
    myBookMarks: [],
    setmyBookMarks: (arr) => set((state) => ({ myBookMarks: arr })),

    
    myLikes: [],
    setmyLikes: (arr) => set((state) => ({ myLikes: arr })),

    
    expSubmitted: [],
    setexpSubmitted: (arr) => set((state) => ({ expSubmitted: arr })),

    
    myMcqsAnsArr: [],
    setmyMcqsAnsArr: (arr) => set((state) => ({ myMcqsAnsArr: arr })),


    fname:"",
    setfname: (val) => set((state) => ({ fname: val })),

    score:null,
    setScore: (val) => set((state) => ({ score: val })),


    loggedInUserEmail:"",
    setloggedInUserEmail: (val) => set((state) => ({ loggedInUserEmail: val })),

    profilePic:"",
    setprofilePic: (val) => set((state) => ({ profilePic: val })),

    loginModel:false,
    setloginModel: (val) => set((state) => ({ loginModel: val })),


    loggedIn:false,
    setloggedIn: (val) => set((state) => ({ loggedIn: val })),

   

  }))

  export default useitemStore;


