import { useState, useRef, useEffect } from "react";
import { Button, Dropdown, Space } from 'antd';
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 
import axios from "axios";
import { yHost }  from "./settings";
import { useNavigate, useLocation } from "react-router-dom";
import useitemStore from "./store"
export default function UpdateExpl() {

    const location = useLocation();
    const [loading, setLoading] = useState(true)

    const [mcqObj, setmcqObj] = useState({})

    const [textbookreference,settextbookreference]= useState("")
    const [explanation,setexplanation]= useState("")
   
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();

    const [pic1url,setpic1url]= useState("")
    const [pic2url,setpic2url]= useState("")


    const formElemRef = useRef()

    const navigate = useNavigate();



   
    const loggedInUserEmail = useitemStore((state) => state.loggedInUserEmail)
    const setloggedInUserEmail = useitemStore((state) => state.setloggedInUserEmail)
    

        useEffect(()=>{
            console.log("state",location.state)
            fetchExpl()
        },[])
    
      const fetchExpl = ()=>{
        axios({
            method: 'post',
            url: `${yHost}/fetchExpl`,
            data: {mcqnum : location.state.mcqnum},
            withCredentials: true //ye cookies carry karega
          })
        .then((response) => {
      
          const data = response.data;
         if(data.msg == "please login"){alert("please login"); navigate("/")}
         setLoading(false)
         if(location.state.ownmcq){
          setexplanation(data.mcqObj.contriexpl);  
          settextbookreference(data.mcqObj.contritextbref)
         }else{
          setexplanation(data.mcqObj.userexplanations.filter((i)=>{ if(i.email = loggedInUserEmail){return i}})[0].expl)
          settextbookreference(data.mcqObj.userexplanations.filter((i)=>{ if(i.email = loggedInUserEmail){return i}})[0].textbref)
            setpic1url(data.mcqObj.userexplanations.filter((i)=>{ if(i.email = loggedInUserEmail){return i}})[0].anspic1)
            setpic2url(data.mcqObj.userexplanations.filter((i)=>{ if(i.email = loggedInUserEmail){return i}})[0].anspic2)
          
         }
        


         setmcqObj(data.mcqObj)
        })
        .catch((error) => console.log(error));
      }

        

          

            function handleChange1(e) {
                // console.log(e.target.files[0])
                // console.log(e.target.files[0].uri)
                // console.log(e.target.files[0].url)
            
                if(e.target.files[0].size > 2000000 ){alert("file should be less then 2 MB"); return}
                setFile1(URL.createObjectURL(e.target.files[0]));
            }

            
            function handleChange2(e) {
              // console.log(e.target.files[0])
              // console.log(e.target.files[0].uri)
              // console.log(e.target.files[0].url)
          
              if(e.target.files[0].size > 2000000 ){alert("file should be less then 2 MB"); return}
              setFile2(URL.createObjectURL(e.target.files[0]));
          }

            
 const handleSubmit=(e)=>{
    e.preventDefault()
    const formData = new FormData(formElemRef.current);
    console.log(formData)

    formData.append("mcqnum",location.state.mcqnum )

    if(location.state.ownmcq){
      axios({
        method:'post',
        url:`${yHost}/updateExplSubmit`, 
        data: formData, 
       withCredentials:true
   })
      .then((response) => {
          const data = response.data;
         if(data.msg == "success"){alert("mcq posted successfully"); navigate(-1)}
        })
        .catch((error) => console.log(error));
    }else{
      axios({
        method:'post',
        url:`${yHost}/updateExplNotOwn`, 
        data: formData, 
       withCredentials:true
   })
      .then((response) => {
          const data = response.data;
         if(data.msg == "success"){alert("updated successfully"); navigate(-1)}
        })
        .catch((error) => console.log(error));
    }


 }


  return (
    <>

          {loading?
          <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
          <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
          </div>
                 :

  <div style={{backgroundColor:"black"}}>

   
        <form style={{marginTop:"40px", marginRight:"auto", marginLeft:"auto", display:"block",  width:"95%", maxWidth:"800px" }} ref={formElemRef} onSubmit={handleSubmit}>
        <h1 style={{color:"orange"}}>Update your Explanation / Textbook reference</h1>
            <p style={{color:"white"}}>Ques: {mcqObj.question}</p>
           
            {mcqObj.contriQuespic ?
            <img style={{ maxWidth:"100px" }} src=""  />:null}

            <p style={{color:"white"}}>A: {mcqObj.option1}</p>
            <p style={{color:"white"}}>B: {mcqObj.option2}</p>
            <p style={{color:"white"}}>C: {mcqObj.option3}</p>
            <p style={{color:"white"}}>D: {mcqObj.option4}</p>
            <p style={{color:"white"}}>Your answer: {mcqObj.ans}</p>
        


                                




            <label htmlFor="textbookreference"><span style={{color:"white", marginRight:"10px", width:"200px"}}>Textbook reference: </span></label>
            <input type="text" id="textbookreference" value={textbookreference}   name="textbookreference" onChange={e => {  settextbookreference(e.target.value)  }}  />
            <br/> <br/>

            <label htmlFor="explanation"><span style={{color:"white", marginRight:"10px", width:"200px"}}>Explanation: </span></label>
            <textarea type="text" id="explanation" value={explanation}  name="explanation"  onChange={e => {  setexplanation(e.target.value)  }} rows="4" cols="50" ></textarea>
            <br/>
            <br/>

          
      
           {location.state.ownmcq ?
            <>
            {!mcqObj.contriAnspic1 ?
            <>
            <label htmlFor="pic1">
            <span style={{color:"white", marginRight:"10px"}}>you can upload image attached with explanation</span> </label>
            <input  name="pic1" type="file" onChange={handleChange1} accept=".jpg,.png" />
            <br/>
        
            {file1 ?  <img style={{ maxWidth:"100px" }} src={file1}  />:null}
            <br/>
            </>
            :
            <>
            {!file1 ? <img src={yHost+"/uploads/" + mcqObj.contriAnspic1   } alt="pic" width="400" height={"auto"}/>:null}
            <br/>
             <label htmlFor="pic1">
            <span style={{color:"white", marginRight:"10px"}}>you can change above image</span> </label>
            <br/>
            <input  name="pic1" type="file" onChange={handleChange1} accept=".jpg,.png" />
            <br/>
            <br/>
            {file1 ?  <img style={{ maxWidth:"100px" }} src={file1}  />:null}
            <br/>
            </>
            }
            
            {!mcqObj.contriAnspic2 ?
            <>
            <label htmlFor="pic2">
            <span style={{color:"white", marginRight:"10px"}}>you can upload one more image, if needed</span> </label>
            <input  name="pic2" type="file" onChange={handleChange2} accept=".jpg,.png" />
            <br/>
        
            {file2 ?  <img style={{ maxWidth:"200px" }} src={file2}  />:null}
            <br/>
            </>
            :
            <>
            {!file2 ? <img src={yHost+"/uploads/" + mcqObj.contriAnspic2   } alt="pic" width="400" height={"auto"}/>:null}
            <br/>
             <label htmlFor="pic2">
                <br/>
            <span style={{color:"white", marginRight:"10px"}}>you can change above image</span> </label>
            <br/>
            <input  name="pic2" type="file" onChange={handleChange2} accept=".jpg,.png" />
            <br/>
            <br/>
            {file2 ?  <img style={{ maxWidth:"200px" }} src={file2}  />:null}
            <br/>
            </>
            }
            </>
            :
            <>
            {! pic1url ?
            <>
            <label htmlFor="pic1">
            <span style={{color:"white", marginRight:"10px"}}>you can upload image attached with explanation</span> </label>
            <input  name="pic1" type="file" onChange={handleChange1} accept=".jpg,.png" />
            <br/>
        
            {file1 ?  <img style={{ maxWidth:"100px" }} src={file1}  />:null}
            <br/>
            </>
            :
            <>
            {!file1 ? <img src={yHost+"/uploads/" + pic1url   } alt="pic" width="400" height={"auto"}/>:null}
            <br/>
             <label htmlFor="pic1">
            <span style={{color:"white", marginRight:"10px"}}>you can change above image</span> </label>
            <br/>
            <input  name="pic1" type="file" onChange={handleChange1} accept=".jpg,.png" />
            <br/>
            <br/>
            {file1 ?  <img style={{ maxWidth:"100px" }} src={file1}  />:null}
            <br/>
            </>
            }
            
            {!pic2url ?
            <>
            <label htmlFor="pic2">
            <span style={{color:"white", marginRight:"10px"}}>you can upload one more image, if needed</span> </label>
            <input  name="pic2" type="file" onChange={handleChange2} accept=".jpg,.png" />
            <br/>
        
            {file2 ?  <img style={{ maxWidth:"200px" }} src={file2}  />:null}
            <br/>
            </>
            :
            <>
            {!file2 ? <img src={yHost+"/uploads/" + pic2url   } alt="pic" width="400" height={"auto"}/>:null}
            <br/>
             <label htmlFor="pic2">
                <br/>
            <span style={{color:"white", marginRight:"10px"}}>you can change above image</span> </label>
            <br/>
            <input  name="pic2" type="file" onChange={handleChange2} accept=".jpg,.png" />
            <br/>
            <br/>
            {file2 ?  <img style={{ maxWidth:"200px" }} src={file2}  />:null}
            <br/>
            </>
            }
            </>
            }


            <button style={{marginTop:"10px", backgroundColor:"orange", padding:"10px"}} className="submitBtn" type="submit" >Submit</button>
        </form>
  </div>

}
   </>
  );
}

