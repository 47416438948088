


import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { yHost }  from "./settings";


export default function Profile() {

    const [file, setFile] = useState();
    const [ loading, setloading ] = useState(true)
    const [ profile, setProfile ] = useState({})
    const [ mcqAnsweredCount, setmcqAnsweredCount] = useState(true)

    const [crop, setCrop] = useState({
      unit: 'px', // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 100,
      height:100
    })
    const formElemRef = useRef()
    const imgRef = useRef()

    const navigate = useNavigate();
 
  

    useEffect(() => {
        fetchProfile();
      }, []);

      function handleChange(e) {
         //console.log("src", e.target.files[0])
        // console.log(e.target.files[0].uri)
        // console.log(e.target.files[0].url)
        // var realWidth = imgRef.current.naturalWidth;
        // var realHeight = imgRef.current.naturalHeight;
        //console.log(imgRef.current.naturalWidth, imgRef.current.naturalHeight)
    

         if(e.target.files[0].size > 512000 ){alert("file should be less then 0.5 MB"); return}
          setFile(URL.createObjectURL(e.target.files[0]));
          console.log(URL.createObjectURL(e.target.files[0]))//blob
          
          let img = new Image()
          img.src = window.URL.createObjectURL(e.target.files[0])
          img.onload = () => {
            console.log(img.width);
           
            console.log(imgRef.current.width)
            console.log(imgRef.current.width/img.width)
           
            
            imgRef.current.style.transform = "scaleX(img.width/imgRef.current.width)"
         
          }
      }
      
      
 const handleSubmit=(e)=>{
    e.preventDefault()

   
    if(profile.password && !profile.cpassword){alert("enter password once again in confirm pasdword field"); return}
    if(profile.password &&   profile.password != profile.cpassword){alert("you entered different password in confirm password field"); return}

      const formData = new FormData(formElemRef.current);

      formData.append('crop', JSON.stringify( crop));
    
    // if(email){data.email = email}
    // if(password){data.password = password }
    // if(phone){data.phone = phone }

     console.log(formData)

    axios({
      method:'post',
      url:`${yHost}/users/updateProfile`, 
      data: formData, 
      withCredentials: true,
 })
    .then((response) => {
        const data = response.data;
       if(data.msg == "updated"){
        alert("updated")
        navigate("/")
    }
      })
      .catch((error) => console.log(error));

 }




  const fetchProfile = () => {
    axios({
      method: "post",
      url: `${yHost}/users/profile`,
      withCredentials: true,
    })
      .then((response) => {
        const data = response.data;
        console.log(data);
        if(data.msg=="please login") {alert("please login"); navigate("/"); return}
        else{
          setProfile(data.userObj);
          setmcqAnsweredCount(data.coransLength);
        }
        setloading(false);
      })
      .catch((error) => console.log(error));
  };


  return (
    <div style={{backgroundColor:"black"}}>
   <div style={{ width:"100%",  marginLeft:"auto", marginRight:"auto",display:"block", borderRadius:"5px", borderColor:"aqua", borderStyle:"solid", paddingLeft:"40px", paddingTop:"40px", marginTop:"5px", paddingRight:"20px"}}>

   <h2 style={{color:"orange", textAlign:"center", marginBottom:"30px"}}>Profile</h2>

   <form ref={formElemRef} onSubmit={handleSubmit}>
  <label htmlFor="fname">
        <span style={{color:"white", marginRight:"20px"}}>first name: </span> </label>
    <input id={"fname"} name="fname" type="text" onChange={(e)=>setProfile(prev=>{ prev.fname = e.target.value; return prev})} minLength={3} maxLength={50} value={profile ? profile.fname:""} />
   <br/><br/>


   <label htmlFor="surname">
        <span style={{color:"white", marginRight:"20px"}}>surname: </span> </label>
    <input id={"surname"} name="surname" type="text" onChange={(e)=>setProfile(prev=>{ prev.surname = e.target.value; return prev})} minLength={3} maxLength={50} value={profile ? profile.surname :""} />
   <br/><br/>

   <label htmlFor="phone">
        <span style={{color:"white", marginRight:"20px"}}>phone: </span> </label>
    <input id={"phone"} name="phone" type="text" onChange={(e)=>setProfile(prev=>{ prev.phone = e.target.value; return prev})} minLength={3} maxLength={50} value={profile? profile.phone:""} />
   <br/><br/>
   <p style={{color:"white"}}>Email: {profile ? profile.email:""}</p>
   <input hidden id={"email"} name="email" type="text" onChange={(e)=>setProfile(prev=>{ prev.email = e.target.value; return prev})} minLength={3} maxLength={50} value={profile ? profile.email:"" } />
   <br/><br/>
   <label htmlFor="ugcollege">
        <span style={{color:"white", marginRight:"20px"}}>college of your under graduate (UG) study: </span> </label>
    <input id={"ugcollege"} name="ugcollege" type="text" onChange={(e)=>setProfile(prev=>{ prev.ugcollege = e.target.value; return prev})} minLength={3} maxLength={50} value={profile? profile.ugcollege : ""} />
   <br/><br/>

   <label htmlFor="country">
        <span style={{color:"white", marginRight:"20px"}}>country: </span> </label>
    <input id={"country"} name="country" type="text" onChange={(e)=>setProfile(prev=>{ prev.country = e.target.value; return prev})} minLength={3} maxLength={50} value={profile? profile.country :""} />
   <br/><br/>

   <label htmlFor="countryCode">
        <span style={{color:"white", marginRight:"20px"}}>country code: </span> </label>
    <input id={"countryCode"} name="codeOfWaNumber" type="text" onChange={(e)=>setProfile(prev=>{ prev.codeOfWaNumber = e.target.value; return prev})} minLength={3} maxLength={50} value={profile ? profile.codeOfWaNumber :""} />
   <br/><br/>

   <p style={{color:"white"}}>Score: {profile ?  profile.totalscore :""}</p>
   <p style={{color:"white"}}>MCQs answered by you: {mcqAnsweredCount}</p>

   <label htmlFor="password">
        <span style={{color:"white", marginRight:"20px"}}>set new password: </span> </label>
    <input id={"password"} name="password" type="password" onChange={(e)=>setProfile(prev=>{ prev.password = e.target.value; return prev})} minLength={3} maxLength={50}  />
   <br/><br/>

   <label htmlFor="cpassword">
        <span style={{color:"white", marginRight:"20px"}}>set new cpassword: </span> </label>
    <input id={"cpassword"} name="cpassword" type="password" onChange={(e)=>setProfile(prev=>{ prev.cpassword = e.target.value; return prev})} minLength={3} maxLength={50}  />
   <br/><br/>
   
  {!file?
   <img src={profile ? `${yHost}/userPics/` + profile.profilePic:"" } style={{
            width: "100%",
            maxWidth: "200px",
            height: "auto",
           
            marginTop: "40px",
          }}
          alt="Picture"
        />:null
    }
        <h3 style={{ color: "white" , marginBottom:"20px"}} >update new profile pic</h3>
        <input  name="pic" type="file" onChange={handleChange} accept=".jpg" />
        {file?
        <ReactCrop  aspect={1} minWidth={70} minHeight={70} maxWidth={700}  maxHeight={700}  crop={crop} onComplete={c => console.log(c) }  onChange={c => setCrop(c) }>
        <img src={file}  ref={imgRef} />
        </ReactCrop>
        :null}
         <br/>  <br/>   
         <p style={{color:"white"}}>crop image for better View, your face should be in center of image</p>
        <button style={{padding:"10px", backgroundColor:"orange"}} className="submitBtn" type="submit" >upload cropped image</button>
        <br/><br/>
         </form> 


  </div>
    </div>
  );
}

