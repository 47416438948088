
import { useEffect, useState , useRef, createRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 

import { Button, Dropdown, Space } from 'antd';
import Modal from 'react-modal';
import { animated, useSpring } from '@react-spring/web'
import moment from "moment";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


import { yHost }  from "./settings";

export default function AllUserAns() {
    const [ ansObj, setansObj] = useState({})
    const location = useLocation();
    const [loading, setloading ] = useState(true)

    useEffect(()=>{
   
       
       if(location.state.ansObj){ console.log( "yaha aaya");  setansObj(location.state.ansObj); setloading(false)}
       else{ console.log(location.state.ansObj, " null hai ", location.state.mcqnum) ; fetchAnsObj(location.state.mcqnum)}
       
     
    },[])

    
    const fetchAnsObj = (mcqnum) => {
        console.log("fetchAnsObj",mcqnum)
        axios({
          method: 'post',
          url: `${yHost}/fetchAnsObj`,
          data: {mcqnum},
          withCredentials:true
      
        })
          .then((response) => {
            const data = response.data; 
            console.log("laya",data.mcqobj.adminAns, data.mcqobj.adminAns=="")
            setloading(false)
            setansObj(data.mcqobj)
           
           
          
          })
          .catch((error) => console.log(error));
      }

      const springsA = useSpring({
        from: { width: '0px' },
        to: { width:  !ansObj.allanswers ? "0%": (ansObj.allanswers.filter((u)=>{ if(u.ansbythisUser == "A"){ return u } }).length * 70 )/ansObj.allanswers.length+"%" },
        config: { duration: '2000' },
      });

      const springsB = useSpring({
        from: { width: '0px' },
        to: { width: !ansObj.allanswers? "0%": (ansObj.allanswers.filter((u)=>{ if(u.ansbythisUser == "B"){ return u } }).length * 70 )/ansObj.allanswers.length+"%"},
        config: { duration: '2000' },
      });

      const springsC = useSpring({
        from: { width: '0px' },
        to: { width: !ansObj.allanswers? "0%": (ansObj.allanswers.filter((u)=>{ if(u.ansbythisUser == "C"){ return u } }).length * 70 )/ansObj.allanswers.length+"%"},
        config: { duration: '2000' },
      });
      const springsD = useSpring({
        from: { width: '0px' },
        to: { width: !ansObj.allanswers? "0%": (ansObj.allanswers.filter((u)=>{ if(u.ansbythisUser == "D"){ return u } }).length * 70 )/ansObj.allanswers.length+"%"},
        config: { duration: '2000' },
      });


      

  return (
  <div style={{backgroundColor:"black"}}>
      
      {loading?
          <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
          <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
          </div>
                 :
    <div style={{backgroundColor:"white" ,paddingLeft:"20px", maxWidth:"700px", width:"95%", marginLeft:"auto", marginRight:"auto"}}>
        
        { (ansObj.question.includes("<p") || ansObj.question.includes("<li") || ansObj.question.includes("<span"))  && ansObj.question.includes("</") ? 
        ReactHtmlParser(ansObj.question)
       :<p>{ansObj.question}</p>}

       <p style={{color:"orange", fontSize:"12px", marginBottom:"10px"}}>posted by {ansObj.postedBy} at {moment(ansObj.createdOn).fromNow()}</p> 
      
       {ansObj.contriQuespic ?
       <img src={yHost+"/uploads/" + ansObj.contriQuespic       } alt="Girl in a jacket" width="50" height="50"/>
       :null}

{ ansObj.option1.includes("<")  && ansObj.option1.includes("</") ? 
        <p>A: {ReactHtmlParser(ansObj.option1)}</p>
      : <p>A: {ansObj.option1}</p>}

{ ansObj.option2.includes("<")  && ansObj.option2.includes("</") ? 
        <p>B: {ReactHtmlParser(ansObj.option2)}</p>
      : <p>B: {ansObj.option2}</p>}


{ ansObj.option3.includes("<")  && ansObj.option3.includes("</") ? 
        <p>C: {ReactHtmlParser(ansObj.option3)}</p>
      : <p>C: {ansObj.option3}</p>}


{ ansObj.option4.includes("<")  && ansObj.option4.includes("</") ? 
        <p>D: {ReactHtmlParser(ansObj.option4)}</p>
      : <p>D: {ansObj.option4}</p>}

{ ansObj.option6? 
ansObj.option5.includes("<")  && ansObj.option5.includes("</") ? 
        <p>E: {ReactHtmlParser(ansObj.option5)}</p>
      : <p>E: {ansObj.option5}</p>
      :null}

{ ansObj.option6? 
 ansObj.option6.includes("<")  && ansObj.option6.includes("</") ? 
        <p>F: {ReactHtmlParser(ansObj.option6)}</p>
      : <p>F: {ansObj.option6}</p>
      :null}

{ ansObj.option7? 
 ansObj.option7.includes("<")  && ansObj.option7.includes("</") ? 
        <p>G: {ReactHtmlParser(ansObj.option7)}</p>
      : <p>G: {ansObj.option7}</p>
      :null}

{ ansObj.option8? 
 ansObj.option8.includes("<")  && ansObj.option8.includes("</") ? 
        <p>H: {ReactHtmlParser(ansObj.option8)}</p>
      : <p>H: {ansObj.option8}</p>
      :null}

{ ansObj.option9? 
 ansObj.option9.includes("<")  && ansObj.option9.includes("</") ? 
        <p>I: {ReactHtmlParser(ansObj.option9)}</p>
      : <p>I: {ansObj.option9}</p>
      :null}

{ ansObj.option10? 
 ansObj.option10.includes("<")  && ansObj.option10.includes("</") ? 
        <p>J: {ReactHtmlParser(ansObj.option10)}</p>
      : <p>J: {ansObj.option10}</p>
      :null}

{ ansObj.option11? 
 ansObj.option11.includes("<")  && ansObj.option11.includes("</") ? 
        <p>K: {ReactHtmlParser(ansObj.option11)}</p>
      : <p>K: {ansObj.option11}</p>
      :null}

{ ansObj.option12? 
 ansObj.option12.includes("<")  && ansObj.option12.includes("</") ? 
        <p>L: {ReactHtmlParser(ansObj.option12)}</p>
      : <p>L: {ansObj.option12}</p>
      :null}

{ ansObj.option13? 
 ansObj.option13.includes("<")  && ansObj.option13.includes("</") ? 
        <p>M: {ReactHtmlParser(ansObj.option13)}</p>
      : <p>M: {ansObj.option13}</p>
      :null}

{ ansObj.option14? 
 ansObj.option14.includes("<")  && ansObj.option14.includes("</") ? 
        <p>N: {ReactHtmlParser(ansObj.option14)}</p>
      : <p>N: {ansObj.option14}</p>
      :null}

{ ansObj.option15? 
 ansObj.option15.includes("<")  && ansObj.option15.includes("</") ? 
        <p>O: {ReactHtmlParser(ansObj.option15)}</p>
      : <p>O: {ansObj.option15}</p>
      :null}

{ ansObj.option16? 
 ansObj.option16.includes("<")  && ansObj.option16.includes("</") ? 
        <p>P: {ReactHtmlParser(ansObj.option16)}</p>
      : <p>P: {ansObj.option16}</p>
      :null}

      { ansObj.option17? 
 ansObj.option17.includes("<")  && ansObj.option17.includes("</") ? 
        <p>Q: {ReactHtmlParser(ansObj.option17)}</p>
      : <p>Q: {ansObj.option17}</p>
      :null}

{ ansObj.option18? 
 ansObj.option18.includes("<")  && ansObj.option18.includes("</") ? 
        <p>R: {ReactHtmlParser(ansObj.option18)}</p>
      : <p>R: {ansObj.option18}</p>
      :null}

{ ansObj.option19? 
 ansObj.option19.includes("<")  && ansObj.option19.includes("</") ? 
        <p>S: {ReactHtmlParser(ansObj.option19)}</p>
      : <p>S: {ansObj.option19}</p>
      :null}

{ ansObj.option20? 
 ansObj.option20.includes("<")  && ansObj.option20.includes("</") ? 
        <p>T: {ReactHtmlParser(ansObj.option20)}</p>
      : <p>T: {ansObj.option20}</p>
      :null}


       <p>Right Answer by {ansObj.postedBy}: {ansObj.ans}</p>
      
       {ansObj.adminAns?
       <p>Right Answer by admin {ansObj.adminAns}</p>
       :null}
       <hr/>

       <p style={{textDecoration:"underline"}}>Users-Answers statistics</p>
{ ansObj.allanswers && ansObj.allanswers.length ?  
<>
<div style={{display:"flex", flexDirection:"row", alignItems: "baseline",  width:"96%", maxWidth:"500px"}}>
<p style={{width:"40%", minWidth:"140px"}}>{ ((ansObj.allanswers.filter((u)=>{ if(u.ansbythisUser == "A"){ return u } }).length * 100 )/ansObj.allanswers.length ).toFixed(1)}% users gave A</p>
<animated.div
      style={{
        marginLeft:"10px",
        height: "20px",
        backgroundColor: 'red',
        ...springsA,
      }}
    />
</div>


<div style={{display:"flex", flexDirection:"row", alignItems: "baseline", width:"96%", maxWidth:"500px"}}>
<p style={{width:"40%", minWidth:"140px"}}>{ ((ansObj.allanswers.filter((u)=>{ if(u.ansbythisUser == "B"){ return u } }).length * 100 )/ansObj.allanswers.length).toFixed(1) }% users gave B</p>
<animated.div
      style={{
        marginLeft:"10px",
        height: "20px",
        backgroundColor: 'green',
        ...springsB,
      }}
    />
</div>


<div style={{display:"flex", flexDirection:"row", alignItems: "baseline", width:"96%", maxWidth:"500px"}}>
<p style={{width:"40%", minWidth:"140px"}}>{ ((ansObj.allanswers.filter((u)=>{ if(u.ansbythisUser == "C"){ return u } }).length * 100 )/ansObj.allanswers.length).toFixed(1) }% users gave C</p>
<animated.div
      style={{
        marginLeft:"10px",
        height: "20px",
        backgroundColor: 'orange',
        ...springsC,
      }}
    />
</div>

<div style={{display:"flex", flexDirection:"row", alignItems: "baseline", width:"96%", maxWidth:"500px"}}>
<p style={{width:"40%", minWidth:"140px"}}>{ ((ansObj.allanswers.filter((u)=>{ if(u.ansbythisUser == "D"){ return u } }).length * 100 )/ansObj.allanswers.length).toFixed(1) }% users gave D</p>
<animated.div
      style={{
        marginLeft:"10px",
        height: "20px",
        backgroundColor: 'grey',
        ...springsD,
      }}
    />
</div>



</>:null}

<hr/>
       {ansObj.userexplanations && ansObj.userexplanations.length ?
<>
<p>{ansObj.userexplanations.length} users posted their explanations</p>
       {ansObj.userexplanations.map((ansItem, ansIndex)=>{ return (
        <div key={"anss"+ansIndex}>
        <p style={{color:"orchid"}}>{ansItem.fname} posted on {moment(ansItem.createdOn).fromNow()}</p>  

        {ansItem.anspic1 ? 
          <>
          <img src={yHost+"/uploads/" + ansItem.anspic1        } alt="t"    style={{width:"100%", maxWidth:"400px",height:"auto"}}/>
          <br/><br/>
          </>
        :null } 

          {ansItem.anspic2 ? 
          <>
          <img src={yHost+"/uploads/" + ansItem.anspic2        } alt="t"    style={{width:"100%", maxWidth:"400px",height:"auto"}}/>
          <br/><br/>
          </>
        :null }  

        <p>He say right answer is {ansItem.useranswer}</p>
        <p>His explanation: {ReactHtmlParser(ansItem.expl)}</p>
        {ansItem.textbref ? <p>His textbook reference: {ansItem.textbref}</p>:null }

         

         <hr/>  
        </div>
       )})}
</>:null
}

{ ansObj.allanswers && ansObj.allanswers.length ?  
<>
{ ansObj.allanswers.map((tItem, tIndex)=>{   return (
  <p key={"ansObj"+tIndex}>{tItem.fname} posted answer is {tItem.ansbythisUser}</p>
) }) }
</>

:null
 }


      
{ ansObj.allanswers && ansObj.allanswers.length ?  
<>
<p>Including above {ansObj.allanswers.length} users posted answers till today</p>

</> :null}


    
    </div>
}
    </div>
  );
}

