

import { useNavigate } from "react-router-dom";
export default function Header() {
  

  return (

<header className="position-absolute w-100">
<div className="container">
    <div className="top-header d-none d-sm-flex justify-content-between align-items-center">
        <div className="contact">
            <a href="tel:+1234567890" className="tel"><i className="fa fa-phone" aria-hidden="true"></i>+91 9223226259</a>
            <a href="mailto:info@yourmail.com"><i className="fa fa-envelope"
                aria-hidden="true"></i>mcqbuster@gmail.com</a>
        </div>

    </div>
    <nav className="navbar navbar-expand-md navbar-light">
        <a className="navbar-brand" href="/"><img src="assets/images/11mcqbuster1.png" alt="Multipurpose" /></a>
        <div className="group d-flex align-items-center">
            <button className="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation"><span
                    className="navbar-toggler-icon"></span></button>

        </div>
        <a className="search-icon d-none d-md-block" href="/"><i className="fa fa-search"></i></a>
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav">
                <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                <li className="nav-item"><a className="nav-link" href="#services">About App</a></li>
                <li className="nav-item"><a className="nav-link" href="https://play.google.com/store/apps/details?id=com.mcqbuster">For Android</a></li>
                <li className="nav-item"><a className="nav-link" href="#weekly">Winner of Weekly Challenge</a></li>
                <li className="nav-item"><a className="nav-link" href="#youtube">Youtube Videos</a></li>
                <li className="nav-item"><a className="nav-link" href="/teachers">Teachers</a></li>

                <li className="nav-item"><a className="nav-link" href="#footer">Contact Us</a></li>
            </ul>
           




        </div>
    </nav>
</div>
</header>

);
}
