import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { yHost }  from "./settings";
import axios from "axios";
import useitemStore from "./store"
import { Button, Dropdown, Space } from 'antd';
import Header from "./Header";
import Footer from "./Footer";

export default function SuccessMakeAccount() {

    const [inputs, setInputs] = useState({})
    const navigate = useNavigate();
    const pwRef = useRef(null)
    const checkboxRef = useRef(null)

    const fname = useitemStore((state) => state.fname)
    const setfname = useitemStore((state) => state.setfname)

    const loggedInUserEmail = useitemStore((state) => state.loggedInUserEmail)
const setloggedInUserEmail = useitemStore((state) => state.setloggedInUserEmail)


    const loginModel = useitemStore((state) => state.loginModel)
const setloginModel = useitemStore((state) => state.setloginModel)

const loggedIn = useitemStore((state) => state.loggedIn)
const setloggedIn = useitemStore((state) => state.setloggedIn)




    const login = async (e) => {
        e.preventDefault();
      
        axios({
          method: 'post',
          url: `${yHost}/users/login`,
          data: inputs
          
        })
      .then((response) => {
      
        const data = response.data;
        console.log(data)
       
        if(data.msg=="You are logged in"){ 
           try{
            setloggedIn(true)
           }catch(E){console.log(E)}
           

            setloginModel(false) ; 

            setfname(data.fname) ;

            setloggedInUserEmail(inputs.email); 

            navigate("/") 
           
      }
        else{alert(data.msg)}
      })
      .catch((error) => console.log(error));
      }

      const showPW = ()=>{
        const checkbox = checkboxRef.current
        const x = pwRef.current
        if(checkbox.checked){
          x.type = "text"
        }
       else{
         
           x.type = "password"
       } 
      }

      const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
      }


    return (
    
     
  <div>
    
<Header/>
   
    
    
      <section id="services" className="services" style={{backgroundColor:"black", minHeight:"800px"}}>
      <div style={{marginTop:"130px", backgroundColor:"pink"}} className="container">
       <h1 style={{color:"orange", textAlign:"center"}}>Email verification done Successfully</h1>
    <form onSubmit={login} className ="MakeAAccount"   >
          <h1
             style={{ color: "orange", textAlign: "center" , padding:"5px", backgroundColor:"black", marginBottom:"10px" }}
          >
            Log In
          </h1>
            <input type="hidden" name="city" id="city"></input>
        
          <div className="loginFormCont">

            <input
              autoFocus
              style={{ width: "300", padding: "10px" }}
              type="text"
              name="email"
              value={inputs.email || ""}
              onChange={handleChange}
              minLength="1"
              placeholder="email or Mobile Number"
            ></input>
            <br />
            <br />

            <input
              style={{ width: "300", padding: "10px" }}
              type="password"
              id="password"
              autoComplete="new-password"
              name="password"
              value={inputs.password || ""}
              onChange={handleChange}
              minLength="3"
              placeholder="password"
              ref={pwRef}
            ></input>
            <br />
            <br />
            <div>
              
              <input
                ref={checkboxRef}
                style={{ width: "20px", height: "20px" }}
                type="checkbox"
                id="checkbox"
                onChange={() => showPW()}
              ></input>
              <label style={{ verticalAlign: "top" }} htmlFor="vehicle1">
                Show Password
              </label>
            </div>

            <button
              type="submit"
              className="submitLoginForm" style={{backgroundColor:"orange", padding:"10px"}}
            >
              Login
            </button>
            </div>
          </form>

     </div> </section>
     
   
  
    <Footer/>
     
    
  </div>
  
    );
  }
  
  